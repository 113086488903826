.Comment-container {
  padding-left: 30px;
  padding-right: 30px;
}

.Commenter {
  text-align: left;
  right: 0;
  bottom: 0px;
}

.Comment {
  border-radius: 5px;
  background: white;
  margin: 10px;
  padding: 10px;
}

.CommentInfo {
  color: black;
  margin-bottom: 10px;
  border-bottom: solid gray 1px;
}

.CommentHeader {
  text-align: left;
}

.CommentHeader h2{
  margin-bottom: 0;
  margin-top: 0;
}

.CommentContent {
  padding: 0;
  margin: 0;
  text-align: left;
  padding: 0 10px;
  background: #e1e1e1;
  color: black;
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
  min-height: 70px;
  width: 90%;
}

.CommentRating {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  width: 5%;
  color: black;
  text-align: center;
}

@media (max-width: 900px) {
  .Comment-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .CommentContent {
    width: 85%;
    float: right;
  }
}