.mainPost {
  margin-bottom: 30px;
  padding-right: 30px;
}

.Home-btn {
  background: white;
  display: inline-block;
  border: solid gray;
  border-radius: 5px;
  padding: 5px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: large;
}