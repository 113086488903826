.Post-container {
  padding-left: 130px;
  padding-right: 130px;
}

.Poster {
  text-align: left;
  right: 0;
  bottom: 0px;
}

.Post {
  border-radius: 5px;
  background: white;
  margin: 10px;
  padding: 10px;
}

.PostInfo {
  color: black;
  margin-bottom: 10px;
  border-bottom: solid gray 1px;
}

.PostHeader {
  text-align: left;
}

.PostHeader h2{
  margin-bottom: 0;
  margin-top: 0;
}

.PostContent {
  padding: 0;
  margin: 0;
  text-align: left;
  padding: 0 10px;
  background: #e1e1e1;
  color: black;
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
  min-height: 70px;
  width: 90%;
}

.PostRating {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  width: 5%;
  color: black;
  text-align: center;
}

@media (max-width: 900px) {
  .Post-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .PostContent {
    width: 85%;
    float: right;
  }
}