.content-container {
  min-height: calc(100vh - 84px);
}

.footer-pin {
  position: relative;
  padding: 5px;
  left: 0;
  bottom: 0;
  text-align: center;
}

.App {
  background-color: #282c34;
  color: white;
  min-height: 100vh;
}

.App-header {
  text-align: left;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  font-weight: 750;
  font-size: 3vh;
  background: #252934;
  border-bottom: solid red;
}

.button-new {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 4px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
}

.button-model {
  background-color: red;
  color: white;
  /* width: 60px; */
  padding: 5px 20px;
  font-size: 2vh;
  margin-right: 10px;
}

.heading-modal {
  border-bottom: solid red;
}

.form-model {
  margin-bottom: 30px;
}

.input-label {
  padding-right: 10px;
  display: inline-block;
  width: 20%;
}

.input-pair {
  padding-bottom: 10px;
}

@media (max-width: 900px) {
  .App-header {
    text-align: center;
  }

  .button-new {
    bottom: 10px;
    right: 10px;
  }
}